import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import Image from 'next/image';
import dayjs from 'dayjs';
import Link from 'next/link';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useRouter } from 'next/router';
import verticalLine from '@public/imgs/mobile/entry-line/home-header-vertical-line.svg';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';
import { LangType, MenuType } from './types';
import TopBarFnPopup from '@/mobileComponents/pageComponents/Home/TopBarFnPopup';

dayjs.extend(utc);
dayjs.extend(timezone);

interface HeaderProps {
  timezone?: string;
  onLogout: () => void;
}

// const prefixName = generateNamePrefix('mobile.home.');

const Header: React.FC<HeaderProps> = (props) => {
  const { i18n } = useTranslation('mobile');
  const { onLogout } = props || {};
  const router = useRouter();
  const [lang, setLang] = useState<LangType>('zh');
  const [menuType, setMenuType] = useState<MenuType>('menu');
  const langEnum = useRef({
    zh: '中文',
    en: 'English',
  });

  useEffect(() => {
    setLang(i18n.language === 'zh' ? 'zh' : 'en');
  }, [i18n.language]);
  const memorizedLangKeys = useMemo(() => {
    return Object.keys(langEnum.current) as LangType[];
  }, []);

  const handleChangeLanguage = useCallback(
    (langKey) => {
      setLang(langKey);
      router.replace(router.asPath, router.asPath, { locale: langKey });
    },
    [router],
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.top}>
        <Link href="/m/home" passHref>
          <div className={styles.logo} />
        </Link>
        <div className={styles.right}>
          {/* <div
            role="button"
            className={styles.dropdown}
            onClick={() => {
              setMenuType('lang');
              toggleDrawer();
            }}
          >
            <div className={styles.earth} />
            <p>{langEnum.current[lang || 'zh']}</p>
            <div className={styles.downArrow} />
          </div> */}
          <Image className={styles.verticalLine} src={verticalLine} />
          <div
            onClick={() => {
              setMenuType('menu');
              toggleDrawer();
            }}
            className={styles.menu}
          />
        </div>
      </div>
      <TopBarFnPopup
        isOpen={isOpen}
        type={menuType}
        toggleDrawer={toggleDrawer}
        langList={memorizedLangKeys}
        lang={lang}
        handleChangeLanguage={handleChangeLanguage}
        onLogout={onLogout}
      />
    </div>
  );
};

export default Header;
