import { useEffect, useMemo, useRef, useState } from 'react';
import Layout from '@/pageComponents/Layout';
import styles from './styles.module.scss';
import cls from 'classnames';
import { IStep3Props } from '@/pageComponents/CancelCourse/Step3';
import { Step1, Step2, Step3, Step4 } from '@/pageComponents/CancelCourse';
import { cancelTrialCourse, getTrialDetail } from '@/api/home';
import avatorDefault from '@public/imgs/trail-course/avator.png';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { ICourseInfo, IPageProps } from '@/pageComponents/CancelCourse/interface';
import {
  STEP_ONE_INDEX,
  STEP_TWO_INDEX,
  STEP_FOUR_INDEX,
  STEP_THREE_INDEX,
  CANCEL_COURSE_STEP1_CONTINUE,
  CANCEL_COURSE_STEP1_THINK_AGAIN,
  CANCEL_COURSE_STEP2_CONTINUE,
  CANCEL_COURSE_STEP2_THINK_AGAIN,
  CANCEL_COURSE_STEP3_CONFIRM,
  CANCEL_COURSE_STEP3_THINK_AGAIN,
  CANCEL_COURSE_STEP4_BACK,
  CANCEL_COURSE_STEP4_RESCHEDULE,
  CANCEL_COURSE_STEP4_BOOK_CLICK,
} from '@/pageComponents/CancelCourse/constants';
import useUser from '@/store/useUser';
import router, { useRouter } from 'next/router';
import { SUBJECT } from '@/api/types/home';
import { Toast } from '@/components';
import { debounce } from 'lodash';
import { GS2_STU_PC, GS2_TEACHER_DETAIL, GS2_TEACHER_LIST, goGs2Booking } from '@/utils/gs2';
import { traceEvent } from '@wk/wk-gatherer';
export const getServerSideProps: GetServerSideProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['trailCourse', 'home', 'common'])),
    },
  };
};
export const CANCEL_COURSE_STEP = 'cancelCourseStep';

const CourseConfirm = (props: IPageProps) => {
  const { query } = useRouter();
  const { t, i18n } = useTranslation(['trailCourse', 'home', 'common']);
  const [step, setStep] = useState<number>(STEP_ONE_INDEX);
  const { curStudent } = useUser();
  const [data, setData] = useState<Partial<ICourseInfo>>();
  const stepRef = useRef<IStep3Props>();
  const isZh = i18n.language === 'zh';

  useEffect(() => {
    getCurStep();
    getCourseData();
  }, []);

  const getCurStep = () => {
    const step = sessionStorage.getItem(CANCEL_COURSE_STEP);
    if (step) {
      setStep(Number(step));
    } else {
      sessionStorage.setItem(CANCEL_COURSE_STEP, String(STEP_ONE_INDEX));
    }
  };

  const getCourseData = async () => {
    try {
      const result = await getTrialDetail({
        courseScheduleId: query?.courseScheduleId as string,
        subject: query?.courseSubject as SUBJECT,
      });
      const { courseSchedule, studentTimeZone, timeZone } = result || {};
      const {
        teacher,
        courseSectionRespDTO,
        duration = 0,
        startDateTime,
        endDateTime,
        classType,
        courseSubject,
        teacher: { uuid: teacherId },
      } = courseSchedule || {};
      const { sectionNameDisplayEn, sectionNameDisplay } = courseSectionRespDTO || {};
      const { fullName, gsName, desc: teacherProfile } = teacher || {};
      const teacherAvator = teacher?.data?.contentUrl || avatorDefault?.src;
      setData({
        sectionNameDisplayEn,
        sectionNameDisplay,
        teacherName: (isZh ? fullName : gsName) || fullName,
        teacherProfile,
        teacherAvator,
        duration,
        startDateTime,
        endDateTime,
        studentTimeZone,
        timeZone,
        teacherId,
        courseSubject,
        classType,
      });
    } finally {
      props.onLoading(false);
    }
  };

  const handleStepSet = (step: number) => {
    setStep(step);
    sessionStorage.setItem(CANCEL_COURSE_STEP, String(step));
  };

  const handleConfirm = debounce(
    async () => {
      const reasons = stepRef.current?.getCheckedReason() || [];
      if (reasons?.length === 0) {
        Toast.error(t('取消约课-理由选择'));
        return;
      }
      await cancelTrialCourse({ reasons: reasons, courseScheduleId: query?.courseScheduleId as string });
      traceEvent({
        data: {
          _event: CANCEL_COURSE_STEP3_CONFIRM,
          ...traceData,
          reason: reasons,
        },
      });
      handleStepSet(STEP_FOUR_INDEX);
    },
    3000,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleBack = () => {
    sessionStorage.removeItem(CANCEL_COURSE_STEP);
    router.back();
  };

  const traceData = useMemo(() => {
    return {
      courseScheduleId: query?.courseScheduleId as string,
      scheduleName: i18n?.language == 'zh' ? data?.sectionNameDisplay : data?.sectionNameDisplayEn,
      subject: data?.courseSubject,
    };
  }, [
    data?.courseSubject,
    data?.sectionNameDisplay,
    data?.sectionNameDisplayEn,
    i18n?.language,
    query?.courseScheduleId,
  ]);

  const routeToGs2Booking = () => {
    traceEvent({
      data: {
        _event: CANCEL_COURSE_STEP4_BOOK_CLICK,
        ...traceData,
      },
    });
    goGs2Booking({
      source: GS2_STU_PC,
      targetStep: GS2_TEACHER_DETAIL,
      trigger_name: CANCEL_COURSE_STEP4_BOOK_CLICK,
    });
  };

  const stepConfig = [
    {
      title: t('取消课程-步骤一标题', { studentName: curStudent?.fullName }),
      leftBthText: t('取消课程-继续'),
      leftBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP1_CONTINUE,
            ...traceData,
          },
        });
        handleStepSet(STEP_TWO_INDEX);
      },
      rightBthText: t('取消课程-我再想想'),
      rightBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP1_THINK_AGAIN,
            ...traceData,
          },
        });
        handleBack();
      },
      content: <Step1 data={data} />,
    },
    {
      title: t('取消课程-步骤二标题', { studentName: curStudent?.fullName }),
      leftBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP2_CONTINUE,
            ...traceData,
          },
        });
        handleStepSet(STEP_THREE_INDEX);
      },
      leftBthText: t('取消课程-继续'),
      rightBthText: t('取消课程-我再想想'),
      rightBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP2_THINK_AGAIN,
            ...traceData,
          },
        });
        handleBack();
      },
      content: <Step2 data={data} />,
    },
    {
      title: t('取消课程-步骤三标题'),
      leftBtnFunc: handleConfirm,
      rightBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP3_THINK_AGAIN,
            ...traceData,
          },
        });
        handleBack();
      },
      leftBthText: t('取消课程-确认'),
      rightBthText: t('取消课程-我再想想'),
      content: <Step3 ref={stepRef} />,
    },
    {
      title: t('取消课程-步骤四标题'),
      leftBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP4_BACK,
            ...traceData,
          },
        });
        handleBack();
      },
      leftBthText: t('取消课程-回到首页'),
      rightBthText: t('取消课程-再次预约'),
      rightBtnFunc: () => {
        traceEvent({
          data: {
            _event: CANCEL_COURSE_STEP4_RESCHEDULE,
            ...traceData,
          },
        });
        sessionStorage.removeItem(CANCEL_COURSE_STEP);
        goGs2Booking({
          source: GS2_STU_PC,
          targetStep: GS2_TEACHER_LIST,
          trigger_name: CANCEL_COURSE_STEP4_RESCHEDULE,
        });
      },
      content: <Step4 data={data} routeToGs2Booking={routeToGs2Booking} />,
    },
  ];

  return (
    <div className={styles.cancelCourseContainer}>
      <div className={styles.centerContent}>
        <div className={styles.confirmInfo}>
          <div className={styles.title}>{stepConfig[step].title}</div>
          {stepConfig[step].content}
        </div>
        <div className={styles.footerBtns}>
          <div className={cls(styles.btn, styles.whiteBtn)} onClick={stepConfig[step].leftBtnFunc}>
            {stepConfig[step].leftBthText}
          </div>
          <div className={cls(styles.btn, styles.colorfulBtn)} onClick={stepConfig[step].rightBtnFunc}>
            {stepConfig[step].rightBthText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseConfirm;

CourseConfirm.getLayout = (page: React.ReactElement) => (
  <Layout hiddenMenu showStudentCenter isProcessingLoading={false} canSwitchStudent>
    {page}
  </Layout>
);
