/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import Image from 'next/image';
import cls from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
// import { CSSTransition } from 'react-transition-group';

// import avatarBg from '@public/imgs/avatar-placeholder.png';
import logo from '@public/imgs/home-logo.png';
// import star from '@public/imgs/five-star.png';
// import avatar from '@public/imgs/pc/student-avatar.svg';
// import arrow from '@public/imgs/left-right-arrow.svg';
import { useRouter } from 'next/router';
// import Cookie from 'js-cookie';
import { StudentInfo } from '@/api/types/home';
// import { Button } from '@/components';
// import PATHS from '@/utils/paths';
import styles from './style.module.scss';
import { generateNamePrefix } from '@/utils/helpers';
import { log } from '@/api/log';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

// const NEED_REFRESH_ROUTES = [PATHS.playback];

const prefixName = generateNamePrefix('home.');

export interface MenuProps {
  students: StudentInfo[] | null;
  curStudent: StudentInfo | null;
  setCurStudent: (data: StudentInfo) => void;
  menuConfigs: {
    icon: StaticImageData;
    activeIcon: StaticImageData;
    title: string;
    path: string;
    activePath: string;
    id: string;
    strict?: boolean;
    renderNews?: (props?: any) => React.ReactNode;
  }[];
  className?: string;
}

const Menu = (props: MenuProps) => {
  const { t, i18n } = useTranslation('home');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [selectVis, setSelectVis] = useState(false);
  const router = useRouter();
  const { menuConfigs, className } = props || {};
  const { pathname } = router;
  const locale = router.locale ?? 'zh';
  const { traceEventWithAccount } = useTraceEventWithAccount();

  return (
    <div className={cls(styles.homeMenu, className)}>
      <div className={styles.logo}>
        <Image draggable={false} src={logo} layout="responsive" />
      </div>
      <div className={styles.menuBox}>
        {menuConfigs.map(({ icon, activeIcon, title, path, activePath, id, strict = false, renderNews }) => {
          const isActive = strict ? pathname === activePath : pathname.startsWith(activePath);
          return (
            <Link key={path} href={path}>
              <div
                key={path}
                className={cls(
                  styles.menuBoxItem,
                  (strict ? pathname === activePath : pathname.startsWith(activePath)) && styles.menuBoxItemActive,
                )}
              >
                <span
                  className={cls({
                    [styles.activeLine]: isActive,
                  })}
                />
                <div
                  className={styles.itemIcon}
                  role="button"
                  onClick={() => {
                    log(prefixName`${id}`);
                  }}
                >
                  <span className={styles.itemIconNormal}>
                    <Image src={isActive ? activeIcon : icon} layout="responsive" />
                  </span>
                  {activeIcon && (
                    <span className={styles.itemIconHover}>
                      <Image src={activeIcon} layout="responsive" />
                    </span>
                  )}
                </div>
                <span className={cls(styles.menuName, locale === 'zh' && styles.menuNameZh)}>
                  {t(title)}
                  {renderNews?.()}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
      <div
        onClick={() => {
          traceEventWithAccount({
            _event: 'U_PC_DownloadClick',
          });
          window.open(`/${i18n.language}/home/downloadPage`);
        }}
        className={styles.downloadAppBtn}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="/imgs/preparations/icon_download.svg"
            alt=""
            className={styles[`dowload-btn-${i18n.language || 'en'}`]}
          />
        </div>
        <div className={styles.downloadLabel}>
          <p>{t('学员中心首页-下载')}</p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
